import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import ConversationAdminControls from '../../components/conversation/AdminControls.jsx';
import ConversationMessages from '../../components/conversation/ConversationMessages.jsx';
import { adjustTextAreaHeight } from '../../utils/textInput.js';
import { getErrorMessage, useCreateNotification } from '../../utils/toast.js';

export default function ViewConversation() {
  const { uuid } = useParams();
  let location = useLocation();
  const role = useSelector((state) => state.auth.role);

  const [conversation, setConversation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newContent, setNewContent] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState(new Set());

  const messagesEndRef = useRef(null);
  const messageInputRef = useRef(null);

  const createNotification = useCreateNotification();

  async function fetchConversation() {
    try {
      const { data } = await axios.get(`/api/conversation/${uuid}`);
      if (
        conversation &&
        conversation.messages.length !== data.messages.length
      ) {
        setNewContent(true);
      }
      setConversation(data);
      setError(null);
    } catch (err) {
      setError(getErrorMessage(err));
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchConversation();
    const intervalId = setInterval(fetchConversation, 10000);
    const params = new URLSearchParams(location.search);
    setIsViewOnly(params.get('viewOnly') === 'true');

    return () => clearInterval(intervalId);
  }, [uuid]);

  useEffect(() => {
    if (newContent) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      setNewContent(false);
      if (messageInputRef.current) {
        messageInputRef.current.focus();
      }
    }
  }, [newContent]);

  async function sendWrittenMessage(e) {
    e.preventDefault();
    if (!message.trim() || isSending) return;

    setIsSending(true);
    try {
      await axios.post(`/api/conversation/${uuid}/written-message`, {
        message: message.trim(),
      });
      setMessage('');
      fetchConversation();
    } catch (error) {
      createNotification({
        message: error,
        type: 'error',
      });
    } finally {
      setIsSending(false);
    }
  }

  if (loading) return <div className="text-center py-4">Loading...</div>;
  if (error)
    return <div className="text-center py-4 text-danger">Error: {error}</div>;
  if (!conversation)
    return <div className="text-center py-4">No conversation found</div>;

  return (
    <>
      {role === 'ADMIN' && (
        <ConversationAdminControls
          conversation={conversation}
          onUpdate={fetchConversation}
          selectedMessages={selectedMessages}
          setSelectedMessages={setSelectedMessages}
        />
      )}
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="card h-100 d-flex flex-column">
              <ConversationMessages
                conversation={conversation}
                role={role}
                onUpdate={fetchConversation}
                messagesEndRef={messagesEndRef}
                selectedMessages={selectedMessages}
                setSelectedMessages={setSelectedMessages}
              />
              {!isViewOnly && (
                <div className="card-footer p-3 border-top">
                  <form onSubmit={sendWrittenMessage} className="d-flex gap-2">
                    <textarea
                      ref={messageInputRef}
                      className="form-control"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                        adjustTextAreaHeight(e.target);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          e.preventDefault();
                          sendWrittenMessage(e);
                        }
                      }}
                      placeholder="Type your message..."
                      disabled={isSending}
                      rows="1"
                    />
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isSending || !message.trim()}
                    >
                      {isSending ? (
                        <span
                          className="spinner-border
                                spinner-border-sm"
                        />
                      ) : (
                        <span>
                          {conversation.Prospect?.messagingAccount ? (
                            `Send with ${
                              conversation.Prospect.messagingAccount.type
                            }`
                          ) : (
                            <i className="bi bi-send"></i>
                          )}
                        </span>
                      )}
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
