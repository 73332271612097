import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import MenuDropdown from '../user/MenuDropdown.jsx';

function Navbar() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const role = useSelector((state) => state.auth.role);

  const baseButtonClass = 'nav-link rounded px-2 py-1 bg-transparent';

  return (
    <nav
      className="__header navbar navbar-expand-lg navbar-light
        bg-light shadow-sm"
    >
      <div className="container-fluid">
        <NavLink className="navbar-brand" to="/">
          SalesWell
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto">
            <li className="nav-item">
              <NavLink className="nav-link" to="/conversation/new">
                Sales Agent
              </NavLink>
            </li>
            {role === 'ADMIN' && (
              <li className="nav-item">
                <NavLink className="nav-link" to="/admin/hello">
                  Admin
                </NavLink>
              </li>
            )}
          </ul>
          <ul className="navbar-nav">
            {isAuthenticated ? (
              <MenuDropdown />
            ) : (
              <>
                <li className="nav-item">
                  <NavLink className={`${baseButtonClass} me-2`} to="/login">
                    Login
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={({ isActive }) =>
                      `${baseButtonClass} ${
                        isActive
                          ? 'text-dark border border-dark'
                          : 'text-secondary border border-secondary'
                      }`
                    }
                    to="/register"
                  >
                    Register
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
