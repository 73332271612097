import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ConversationTypeSelector from '../../../components/conversation/ConversationTypeSelector.jsx';
import CustomCard from '../../../components/utils/CustomCard.jsx';
import Loader from '../../../components/utils/Loader.jsx';
import { useCreateNotification } from '../../../utils/toast.js';

// TODO: Allow admins to edit or create user access to messaging accounts.
export default function CreateEditMessagingAccount() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: undefined,
    type: 'INSTAGRAM',
    accessToken: '',
    pageId: '',
    pageName: '',
    expirationDate: undefined,
    conversationTypeId: '',
  });

  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(Boolean(id));
  const createNotification = useCreateNotification();

  async function getMessagingAccount() {
    try {
      const { data } = await axios.get(`/api/admin/messaging-accounts/${id}`);
      setFormData({
        ...data,
        conversationTypeId: data.conversationTypeId || '',
      });
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      getMessagingAccount();
    }
  }, [id]);

  async function handleSubmit(e) {
    e.preventDefault();
    if (submitting) return;
    setSubmitting(true);

    try {
      formData.conversationTypeId = formData.conversationTypeId || null;
      await axios.post('/api/admin/messaging-accounts/create-edit', formData);
      navigate('/admin/messaging-accounts/list');
      createNotification({
        message: 'Account created or updated successfully',
        type: 'success',
      });
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    } finally {
      setSubmitting(false);
    }
  }

  function handleConversationTypeSelect(value) {
    setFormData((prev) => ({
      ...prev,
      conversationTypeId: Number(value),
    }));
  }

  if (loading) return <Loader />;

  return (
    <CustomCard width={6}>
      <h3 className="mb-4">{id ? 'Edit' : 'Create'} Messaging Account</h3>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Type</label>
          <select
            name="type"
            className="form-select"
            value={formData.type}
            onChange={(e) => setFormData({ ...formData, type: e.target.value })}
            required
          >
            <option value="INSTAGRAM">INSTAGRAM</option>
            <option value="FACEBOOK">FACEBOOK</option>
            <option value="TWILIO">TWILIO</option>
            <option value="SMS_GATEWAY">SMS_GATEWAY</option>
          </select>
        </div>
        <div className="mb-3">
          <label className="form-label">Conversation Type</label>
          <ConversationTypeSelector
            onSelect={handleConversationTypeSelect}
            value={formData.conversationTypeId}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Access Token*</label>
          <input
            type="text"
            name="accessToken"
            className="form-control"
            value={formData.accessToken}
            onChange={(e) =>
              setFormData({ ...formData, accessToken: e.target.value })
            }
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Page ID*</label>
          <input
            type="text"
            name="pageId"
            className="form-control"
            value={formData.pageId}
            onChange={(e) =>
              setFormData({ ...formData, pageId: e.target.value })
            }
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Page Name*</label>
          <input
            type="text"
            name="pageName"
            className="form-control"
            value={formData.pageName}
            onChange={(e) =>
              setFormData({ ...formData, pageName: e.target.value })
            }
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Expiration Date</label>
          <input
            type="date"
            name="expirationDate"
            className="form-control"
            value={formData.expirationDate}
            onChange={(e) =>
              setFormData({ ...formData, expirationDate: e.target.value })
            }
          />
        </div>
        <div className="d-flex gap-2">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={submitting}
          >
            {submitting ? 'Saving...' : 'Save'}
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => navigate('/admin/messaging-accounts/list')}
          >
            Cancel
          </button>
        </div>
      </form>
    </CustomCard>
  );
}
