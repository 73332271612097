import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ConversationTypeSelector from '../../components/conversation/ConversationTypeSelector.jsx';
import { useCreateNotification } from '../../utils/toast.js';

export default function NewConversation() {
  const [selectedTypeId, setSelectedTypeId] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();
  const createNotification = useCreateNotification();

  async function startConversation() {
    if (!selectedTypeId || submitting) return;

    setSubmitting(true);
    try {
      const { data } = await axios.post('/api/conversation/new', {
        conversationTypeId: selectedTypeId,
      });
      navigate(`/conversation/${data.uuid}`);
    } catch (error) {
      createNotification({
        message: error,
        type: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center min-vh-100">
        <div className="col-md-6">
          <div className="card shadow hover-shadow-lg transition">
            <div className="card-body">
              <h5 className="card-title mb-4">Start a New Conversation</h5>
              <div className="mb-3">
                <ConversationTypeSelector onSelect={setSelectedTypeId} />
              </div>
              <button
                className="btn btn-primary w-100 transition"
                onClick={startConversation}
                disabled={!selectedTypeId}
              >
                Start new conversation
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
