import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';

import { truncateText } from '../../utils/string.js';
import { useCreateNotification } from '../../utils/toast.js';

const MAX_TEXT_LENGTH = 70;

// TODO: Receive the filters from the parent component and call handleFilterChange
// instead of separate function
export default function ConversationTypeSelector({
  onSelect = null,
  setSelectedConversation = null,
  value = '',
}) {
  const [conversationTypes, setConversationTypes] = useState([]);
  const [selectedTypeId, setSelectedTypeId] = useState('');

  const createNotification = useCreateNotification();

  const getConversationTypes = useCallback(async () => {
    try {
      const { data } = await axios.get('/api/conversation-type/listt');
      setConversationTypes(data);
    } catch (error) {
      createNotification({
        message: error,
        type: 'error',
      });
    }
  }, []);

  useEffect(() => {
    getConversationTypes();
    const searchParams = new URLSearchParams(location.search);
    setSelectedTypeId(Number(searchParams.get('conversationType')) || value);
  }, []);

  function handleSelect(event) {
    const value = event.target.value;
    setSelectedTypeId(value);
    if (onSelect) {
      onSelect(value);
    }

    if (setSelectedConversation) {
      const selectedConversation = conversationTypes.find(
        (type) => type.id === parseInt(value),
      );
      setSelectedConversation(selectedConversation);
    }
  }

  return (
    <select
      className="form-select"
      value={selectedTypeId}
      onChange={handleSelect}
      aria-label="Select conversation type"
    >
      <option value="">Select a conversation type</option>
      {conversationTypes.map((type) => (
        <option key={type.id} value={type.id}>
          {truncateText(type.rolePrompt, MAX_TEXT_LENGTH)}
        </option>
      ))}
    </select>
  );
}
