import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useCreateNotification } from '../../utils/toast.js';
import CustomCard from '../utils/CustomCard.jsx';

export default function MessagingAccounts({ email }) {
  const [messagingAccounts, setMessagingAccounts] = useState([]);
  const createNotification = useCreateNotification();

  useEffect(() => {
    axios.get(`/api/user/${email}/messaging-accounts`).then(({ data }) => {
      setMessagingAccounts(data.messagingAccounts);
    });
  }, [email]);

  async function handleDelete(messagingAccountId) {
    if (
      window.confirm(
        'Deleting this account will remove all conversations associated ' +
          'with it. Are you sure you want to delete this account?',
      )
    ) {
      try {
        await axios.delete(`/api/messaging-accounts/${messagingAccountId}`);
        setMessagingAccounts(
          messagingAccounts.filter(
            (account) => account.messagingAccountId !== messagingAccountId,
          ),
        );
        createNotification({
          message: 'Account deleted successfully',
          type: 'success',
        });
      } catch (error) {
        createNotification({
          message: error,
          type: 'error',
        });
      }
    }
  }

  return (
    <CustomCard width={6}>
      <h3 className="mb-4">Connected Accounts</h3>
      {messagingAccounts.map((account) => (
        <div
          key={account.messagingAccountId}
          className="d-flex justify-content-between align-items-center p-3
            border-bottom w-100"
        >
          <div className="d-flex align-items-center flex-grow-1">
            <i
              className={`lab ${
                account.messagingAccount.type === 'FACEBOOK'
                  ? 'la-facebook'
                  : 'la-instagram'
              } me-3 fs-4`}
            ></i>
            {account.messagingAccount.pageName}
          </div>
          {account.messagingAccount.conversationType && (
            <Link
              to={`/conversation/list?conversationType=${
                account.messagingAccount.conversationType.id
              }`}
              className="btn btn-sm btn-outline-primary me-2"
            >
              View Conversations
            </Link>
          )}
          <button
            onClick={() => handleDelete(account.messagingAccountId)}
            className="btn btn-sm btn-outline-danger"
          >
            Delete account
            <i className="las la-trash-alt ms-2"></i>
          </button>
        </div>
      ))}
    </CustomCard>
  );
}
