import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCreateNotification } from '../../utils/toast.js';

export default function CreateEditConversationPhase({
  conversationType,
  conversationPhase,
}) {
  const [phase, setPhase] = useState(conversationPhase);
  const navigate = useNavigate();
  const createNotification = useCreateNotification();

  async function save() {
    const data = {
      ...phase,
      conversationType: conversationType.id,
    };
    try {
      const response = await axios.post(
        '/api/admin/conversation-phase/create-edit',
        data,
      );
      console.log(response.data);
      navigate(`/admin/conversation-type/${conversationType.id}/edit`);
      window.location.reload();
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    }
  }

  async function deletePhase() {
    if (window.confirm('Delete this phase?')) {
      try {
        await axios.delete(`/api/admin/conversation-phase/${phase.id}`);
        createNotification({
          message: 'Phase deleted successfully',
          type: 'success',
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (error) {
        createNotification({ message: error, type: 'error' });
      }
    }
  }

  return (
    <tr>
      <td>
        <input
          type="text"
          value={phase.index}
          className="form-control"
          onChange={(e) => setPhase({ ...phase, index: e.target.value })}
        />
      </td>
      <td>
        <textarea
          value={phase.prompt}
          className="form-control"
          onChange={(e) => setPhase({ ...phase, prompt: e.target.value })}
          rows="15"
        />
      </td>
      <td>
        <button className="btn btn-primary" onClick={save}>
          Save
        </button>
        <button className="btn btn-outline-danger ms-2" onClick={deletePhase}>
          <i className="bi bi-trash me-2 align-middle"></i>
          Delete
        </button>
      </td>
    </tr>
  );
}
