import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import MessagingAccountRow from '../../../components/messaging/MessagingAccountRow.jsx';
import { useCreateNotification } from '../../../utils/toast.js';

export default function MessagingAccountsList() {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const createNotification = useCreateNotification();

  async function getMessagingAccountsList() {
    try {
      const { data } = await axios.get('/api/admin/messaging-accounts/list');
      setAccounts(data);
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getMessagingAccountsList();
  }, []);

  async function deleteAccount(id) {
    if (
      window.confirm(
        'Are you sure you want to delete this account? This will ' +
          'delete all conversations related to this account',
      )
    ) {
      try {
        await axios.delete(`/api/messaging-accounts/${id}`);
        setAccounts(accounts.filter((account) => account.id !== id));
        createNotification({
          message: 'Account deleted successfully',
          type: 'success',
        });
      } catch (error) {
        createNotification({ message: error, type: 'error' });
      }
    }
  }

  if (loading) return <div>Loading...</div>;

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Messaging Accounts</h1>
      <Link
        to="/admin/messaging-accounts/create"
        className="btn btn-primary mb-3"
      >
        Create Messaging Account
      </Link>
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th>ID</th>
            <th>Type</th>
            <th>Page Name</th>
            <th>Page ID</th>
            <th>Users</th>
            <th>Expiration</th>
            <th>Conversation Type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {accounts.map((account) => (
            <MessagingAccountRow
              key={account.id}
              account={account}
              onDelete={deleteAccount}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
