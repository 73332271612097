import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { truncateText } from '../../utils/string.js';
import { useCreateNotification } from '../../utils/toast.js';
import CustomCard from '../utils/CustomCard.jsx';

export default function ConversationAdminControls({
  conversation,
  onUpdate,
  selectedMessages,
  setSelectedMessages,
}) {
  const [newPhase, setNewPhase] = useState(conversation.phase || 0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const createNotification = useCreateNotification();
  const navigate = useNavigate();

  async function onEditPhase() {
    if (newPhase === conversation.phase || isSubmitting) return;
    setIsSubmitting(true);

    try {
      await axios.patch(`/api/admin/conversation/${conversation.id}`, {
        phase: newPhase,
      });
      onUpdate();
      createNotification({ message: 'Phase updated', type: 'success' });
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  }

  async function onDeleteConversation() {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      await axios.delete(`/api/admin/conversation/${conversation.id}`);
      createNotification({ message: 'Conversation deleted', type: 'success' });
      navigate('/conversation/new');
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  }

  async function onDeleteMessages() {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const messageIds = Array.from(selectedMessages);
      await axios.delete(`/api/admin/conversation/messages`, {
        data: { messageIds },
      });
      onUpdate();
      setSelectedMessages(new Set());
      createNotification({ message: 'Messages deleted', type: 'success' });
    } catch (error) {
      createNotification({ message: error, type: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <CustomCard width={8}>
      <label className="form-label">Admin Controls</label>
      <div className="mt-2 mb-2 w-100">
        <label className="form-label">Edit Phase:</label>
        <select
          className="form-select"
          value={newPhase}
          onChange={(e) => setNewPhase(Number(e.target.value))}
        >
          <option value={-1}>Phase -1: ERROR</option>
          {conversation.ConversationType.conversation_phases.map((phase) => (
            <option key={phase.index} value={phase.index}>
              Phase {phase.index}: {truncateText(phase.prompt, 50)}
            </option>
          ))}
          <option
            value={conversation.ConversationType.conversation_phases.length}
          >
            Phase {conversation.ConversationType.conversation_phases.length}:
            END_CONVERSATION
          </option>
        </select>
        <button
          className="btn btn-outline-primary btn-sm w-100 mt-2"
          onClick={onEditPhase}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <span className="spinner-border spinner-border-sm me-2" />
          ) : (
            <i className="bi bi-pencil me-2"></i>
          )}
          Update Phase
        </button>
        <div className="mt-3">
          <label className="form-label">Edit Conversation:</label>
          <button
            className="btn btn-outline-danger btn-sm w-100 mb-2"
            onClick={onDeleteConversation}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <span className="spinner-border spinner-border-sm me-2" />
            ) : (
              <i className="bi bi-trash me-2"></i>
            )}
            Delete Conversation
          </button>
          <div className="mt-2 d-flex align-items-center">
            <label className="btn btn-outline-primary btn-sm me-2 flex-grow-1">
              <input
                className="me-2"
                type="checkbox"
                checked={selectedMessages.size === conversation.messages.length}
                onClick={(e) =>
                  e.target.checked
                    ? setSelectedMessages(
                        new Set(
                          conversation.messages.map((message) => message.id),
                        ),
                      )
                    : setSelectedMessages(new Set())
                }
              />
              Select all
            </label>
            <button
              className="btn btn-outline-warning btn-sm flex-grow-1"
              onClick={onDeleteMessages}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <span className="spinner-border spinner-border-sm me-2" />
              ) : (
                <i className="bi bi-trash me-2"></i>
              )}
              Delete Messages ({selectedMessages.size})
            </button>
          </div>
        </div>
      </div>
    </CustomCard>
  );
}
