import React from 'react';
import { Link } from 'react-router-dom';

export default function MessagingAccountRow({ account, onDelete }) {
  return (
    <tr key={account.id}>
      <td>{account.id}</td>
      <td>{account.type}</td>
      <td>{account.pageName}</td>
      <td>{account.pageId}</td>
      <td>
        {account.user_messaging_accounts.length > 0 ? (
          <ul className="list-unstyled mb-0">
            {account.user_messaging_accounts.map((userMessagingAccount) => (
              <li key={userMessagingAccount.user.email}>
                {userMessagingAccount.user.email}
              </li>
            ))}
          </ul>
        ) : (
          <span className="text-muted">No users assigned</span>
        )}
      </td>
      <td>
        {account.expirationDate ? (
          new Date(account.expirationDate).toLocaleDateString()
        ) : (
          <span className="text-muted">N/A</span>
        )}
      </td>
      <td>
        {account.conversationTypeId ? (
          <Link
            to={`/admin/conversation-type/${account.conversationTypeId}/edit`}
            className="badge bg-success text-decoration-none text-white"
            style={{ color: 'inherit' }}
          >
            Type {account.conversationTypeId}
          </Link>
        ) : (
          <span className="badge bg-warning text-dark">Not assigned</span>
        )}
      </td>
      <td>
        <div className="btn-group" role="group">
          <Link
            to={`/admin/messaging-accounts/${account.id}/edit`}
            className="btn btn-warning btn-sm"
          >
            Edit
          </Link>
          <Link
            to={
              '/conversation/list?conversationType=' +
              `${account.conversationTypeId}`
            }
            className="btn btn-info btn-sm"
          >
            Conversations
          </Link>
          <button
            type="button"
            className="btn btn-danger btn-sm"
            onClick={() => onDelete(Number(account.id))}
          >
            Delete
          </button>
        </div>
      </td>
    </tr>
  );
}
